import { render, staticRenderFns } from "./FrzChamberDispatchTunnels.vue?vue&type=template&id=ec59698e"
import script from "./FrzChamberDispatchTunnels.vue?vue&type=script&lang=js"
export * from "./FrzChamberDispatchTunnels.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports